.one-to-many {
  height: 48px;
  width: 100%;
  position: relative;

  &.active {
    .wrap {
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
      .list {
        height: auto;
        transform: scaleY(1);
      }
    }
  }

  .wrap {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    background-color: $color-fresh;
    cursor: pointer;

    .title {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-black;
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
    }

    .list {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      transform: scaleY(0);
      height: 0;
      transition: all .1s linear;
      transform-origin: top;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        color: $color-black;
        font-size: 16px;
        border-top: 1px solid $color-clean;
      }
    }
  }
}
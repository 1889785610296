.music-item-page {
  display: flex;
  justify-content: center;
  form {
    margin-top: 36px;
    width: 300px;

    .text-field {
      margin-bottom: 16px;
    }

    .checkers {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;

      h4 {
        margin-bottom: 8px;
        color: $color-clean;
      }

      .checkbox {
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
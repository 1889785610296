.header {
  background-color: $color-clean;
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &__container {
    width: 1280px;
    max-width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu {
      display: flex;
      &__item {
        margin-right: 16px;
        color: $color-black;
        font-family: $font-montserrat;
        font-weight: 500;

        &.active {
          color: $color-orange;
        }
      }
    }

    .logout {
      width: auto;
      padding: 0 16px;
      height: 40px;
    }
  }
}
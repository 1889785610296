.text-field {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid $color-fresh;
  position: relative;
  box-sizing: border-box;

  &.error {
    border-color: $color-orange;
  }

  .label {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: $color-fresh;
    transition: all .2s linear;
    opacity: 0.5;

    &.active {
      transform: translateY(0);
      top: 7px;
      font-size: 10px;
      color: $color-orange;
      opacity: 1;
    }
  }

  input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    box-sizing: border-box;
    padding: 0 16px;
    color: $color-fresh;
    font-family: $font-montserrat;
    padding-top: 8px;


  }
}
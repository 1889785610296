.music-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .filter {
    width: 1280px;
    max-width: 90%;
    margin-top: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-field, .one-to-many {
      width: calc(100% / 3 - 32px / 3);
    }
  }

  &__list {
    width: 1280px;
    max-width: 90%;
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    .item {
      display: flex;
      padding: 16px 0;
      border-top: 1px solid $color-clean;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__name {
        width: 200px;
        color: $color-fresh;
        font-size: 16px;
        font-weight: 500;
        margin-left: 16px;
      }

      &__artist {
        width: 200px;
        color: $color-fresh;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .button {
        width: 120px;
        height: 40px;
        margin-left: 16px;

        &.delete {
          background-color: $color-orange;
        }
      }

      &__filename {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 16px;
        border-color: transparent transparent transparent $color-fresh;
      }

      &:last-child {
        border-bottom: 1px solid $color-clean;
      }
    }
  }
  .more {
    width: 200px;
    margin-top: 32px;
  }
}
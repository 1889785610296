.musical-categories {
  display: flex;
  justify-content: center;

  &__list {
    flex-direction: column;
    margin-top: 64px;
    width: 300px;

    a {
      width: 100%;
      height: 40px;
      background-color: $color-fresh;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      color: $color-black;

      &:hover {
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
      }
      &:active {
        box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.25);
      }
    }
  }
}
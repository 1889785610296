.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-black;
  min-height: 100vh;

  form {
    width: 300px;

    .password {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}
.category-item{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-black;
  min-height: 100vh;

  form {
    width: 300px;

    .input {
      margin-bottom: 8px;
    }
  }
  .breath {
    margin-left: 36px;
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .breath-circle {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background-color: $color-fresh-alpha;

      &__animate {
        border-radius: 50%;
        background-color: $color-fresh;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transform-origin: center;
      }
    }
    .comment {
      margin-top: 16px;
      height: 30px;
      color: $color-clean;
      font-size: 24px;

    }
  }
}
.file-loader {
  width: 100%;
  height: 80px;

  .button {
    position: relative;
    overflow: hidden;
    .progress {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-orange;
      height: 100%;
    }
    .percent {
      color: $color-black;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    display: none;
  }

  a {
    color: $color-clean;
    font-size: 12px;
    font-family: $font-montserrat;
  }
}
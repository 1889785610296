@keyframes circle_animate {
  from {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
  }
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $color-black;
  display: flex;
  align-items: center;
  justify-content: center;

  &__circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $color-fresh-alpha;
    opacity: 0.5;
    .loader {
      background-color: $color-fresh;
      animation: ease-in-out;
      animation-name: circle_animate;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      transform-origin: center;
    }
  }
}
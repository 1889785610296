.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;

  .checker {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid $color-fresh;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: $color-fresh;
        border-radius: 2px;
      }
    }

  }

  span {
    color: $color-fresh;
    font-weight: 500;
    font-family: $font-montserrat;
    font-size: 12px;
    height: 100%;
    user-select: none;
  }
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic');


//Colors
$color-fresh: #4ABDAC;
$color-fresh-alpha: rgba(74, 189, 172, 0.1);
$color-orange: #FC4A1A;
$color-sunshine: #F7B733;
$color-clean: #DFDCE3;
$color-black: #333333;

//Fonts
$font-montserrat: 'Montserrat', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;


body, html {
  padding: 0;
  margin: 0;
  font-family: $font-open-sans;

  .page {
    background-color: $color-black;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding-top: 60px;

  }

  a {
    text-decoration: none;
    outline: none;
  }
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }

  input,
  textarea {
    outline: none;
    resize: none;
  }

  .button {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-fresh;
    width: 100%;
    height: 48px;
    color: $color-black;
    box-sizing: border-box;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: bold;

    &.add-button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: fixed;
      right: 32px;
      bottom: 32px;
      font-size: 24px;
    }

    &:hover {
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
    }
    &:active {
      box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.25);
    }
  }

}




//Pages
@import "./Pages/Auth/auth";
@import "./Pages/Home/home";
@import "./Pages/Music/music";
@import "./Pages/CategoriesItem/category-item";
@import "./Pages/Categories/category";
@import "./Pages/MusicItem/music-item";


//Components
@import "./Components/TextField/textfield";
@import "./Components/Preloader/preloader";
@import "./Components/Header/header";
@import "./Components/ContextMenu/context-menu";
@import "./Components/FileLoader/file-loader";
@import "./Components/Checkbox/checkbox";
@import "./Components/OneToMany/one-to-many";
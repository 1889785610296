.context-menu {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: $color-clean;
  flex-direction: column;
  position: absolute;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
  width: 120px;
  border-radius: 4px;
  overflow: hidden;

  &__item {
    cursor: pointer;
    width: 100%;
    position: relative;
    padding: 8px;

    &:hover::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-orange;
      height: 100%;
      width: 4px;
    }
  }
}